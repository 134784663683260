import { useQuery } from '@apollo/client';
import * as Sentry from '@sentry/react';
import React, {
  createContext,
  ReducerState,
  useContext,
  useEffect,
  useMemo,
  useReducer,
} from 'react';
import { createReducer, useLocalStorage } from 'react-use';
import { Loader } from '../../components/Loader';
import {
  GetUserIdentitiesDocument,
  GetUserIdentitiesQuery,
  IdentityFragment,
  IdentityFragmentDoc,
  UserIdentity,
} from '../../services/graphql/types/graphql';
import { FragmentType, getFragmentData } from '../../services/graphql/types';

export const IdentityContext = createContext<null | {
  userIdentities: GetUserIdentitiesQuery;
  currentIdentity: IdentityFragment | null | undefined;

  availableClasses?: GetUserIdentitiesQuery['schools'][0]['classes'];
  currentClassId: string;
  setCurrentClassId: ReturnType<typeof useLocalStorage<string>>[1];
  currentClass?: GetUserIdentitiesQuery['schools'][0]['classes'][0];

  availableSchools?: GetUserIdentitiesQuery['schools'];
  currentSchoolId: string;
  setCurrentSchoolId: ReturnType<typeof useLocalStorage<string>>[1];
  currentSchool?: GetUserIdentitiesQuery['schools'][0];

  availableCourses?: GetUserIdentitiesQuery['user']['userCourses'];
  currentCourseId: string;
  setCurrentCourseId: ReturnType<typeof useLocalStorage<string>>[1];
  currentCourse?: GetUserIdentitiesQuery['user']['userCourses'][0];
}>(null);

export const IdentityContextProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const { loading, error, data: userIdentities } = useQuery(GetUserIdentitiesDocument);
  useEffect(() => {
    if (error) {
      Sentry.captureException(error);
    }
  }, [error]);

  // School
  const lsCurrentSchoolIdStorage = useLocalStorage('CurrentSchoolId', '');
  const [currentSchoolId, setCurrentSchoolId] = lsCurrentSchoolIdStorage;

  const availableSchools = useMemo(() => userIdentities?.schools, [userIdentities]);

  const currentSchool = useMemo(
    () => availableSchools?.find((s) => s.schoolId === currentSchoolId),
    [availableSchools, currentSchoolId],
  );

  //Class
  const lsCurrentClassIdStorage = useLocalStorage('CurrentClassId', '');
  const [currentClassId, setCurrentClassId] = lsCurrentClassIdStorage;

  const currentClass = useMemo(
    () => currentSchool?.classes.find((c) => c.classId === currentClassId),
    [currentSchool, currentClassId],
  );

  // Course
  const lsCurrentCourseIdStorage = useLocalStorage('CurrentCourseId', '');
  const [currentCourseId, setCurrentCourseId] = lsCurrentCourseIdStorage;

  const availableCourses = useMemo(
    () => userIdentities?.user.userCourses.filter(({ course }) => course.courseLevel),
    [userIdentities],
  );
  useEffect(() => {
    if (!availableCourses) {
      return;
    }
    console.info('availableCourses', availableCourses);
    if (!availableCourses?.map((c) => c.course.courseId).includes(currentCourseId ?? '')) {
      setCurrentCourseId(availableCourses?.[0]?.course.courseId);
      console.info(`Reset currentCourseId to ${availableCourses?.[0]?.course.courseId}`);
    }
  }, [availableCourses]);

  const currentCourse = useMemo(() => {
    return availableCourses?.find((c) => c.course.courseId === currentCourseId);
  }, [availableCourses, currentCourseId]);

  useEffect(() => {
    if (!currentCourse) {
      return;
    }
    console.info(
      `Course switched to ${currentCourse.course.name}`,
    );
  }, [currentCourse]);

  const currentIdentity = useMemo(() => {
    const school = userIdentities?.schools?.find((sc) => sc.schoolId === currentSchoolId);
    const clas = school?.classes.find((c) => c.classId === currentClassId);
    return getFragmentData(IdentityFragmentDoc, clas?.selfIdentity);
  }, [currentClassId, currentSchoolId, userIdentities?.schools]);

  const availableClasses = useMemo(() => {
    return availableSchools?.find((s) => s.schoolId === currentSchoolId)?.classes;
  }, [availableSchools, currentSchoolId]);

  useEffect(() => {
    if (!currentIdentity) {
      return;
    }
    console.info(
      `Identity switched to ${currentIdentity.school.name} ${currentIdentity.class.name} ${currentIdentity.identity}`,
    );
  }, [currentIdentity]);

  // useEffect(() => console.info('lsCurrentClassId', lsCurrentClassId.value), [lsCurrentClassId.value]);
  // useEffect(() => console.info('lsCurrentSchoolId', lsCurrentSchoolId.value), [lsCurrentSchoolId.value]);

  useEffect(() => {
    if (!userIdentities) {
      return;
    }

    if (!currentSchoolId || !currentClassId) {
      setCurrentSchoolId(userIdentities.schools[0]?.schoolId);
    }

    if (!currentClassId) {
      setCurrentClassId(userIdentities.schools[0]?.classes[0]?.classId);
    }

    if (!currentCourseId) {
      setCurrentCourseId(userIdentities.user.userCourses[0]?.course.courseId);
    }
  }, [currentClassId, currentSchoolId, setCurrentClassId, setCurrentSchoolId, userIdentities]);

  if (!userIdentities || !currentClassId || !currentSchoolId || !currentCourseId) {
    return <Loader fullscreen></Loader>;
  }

  return (
    <IdentityContext.Provider
      value={{
        userIdentities,
        currentIdentity,

        currentClassId,
        currentClass,
        setCurrentClassId,
        availableClasses,

        currentSchoolId,
        currentSchool,
        setCurrentSchoolId,
        availableSchools,

        currentCourseId,
        currentCourse,
        setCurrentCourseId,
        availableCourses,
      }}
    >
      {children}
    </IdentityContext.Provider>
  );
};
